import React, { Component } from 'react';
import $ from 'jquery';
import {
	formatPhoneText,
	formatDate,
	formatZipCode,
	formatDateAPI,
	isMobile,
	scrollToTop,
	regExValidation,
	grabUTMparams,
	grabResponseParam,
	isObjEmpty,
	replaceAllHelper,
	generateUniqueID
} from '../../helpers';
import Button from '../atoms/button';
import Heading from '../molecules/heading';
import QuestionnaireStep from '../molecules/questionnaire-step';
import Loader from '../molecules/loader';

class Questionnaire extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nextButtonLabel: this.props.content.buttons.next,
			submitButtonLabel: this.props.content.buttons.submit,
			messages: this.props.content.messages,
			onSubmitMessage: this.props.onSubmitMessage,
			thankYouURL: this.props.thankYouURL,
			disclaimer: this.props.disclaimer,
			steps: this.props.steps,
			verifyInformation: this.props.verifyInformation,
			totalSteps: this.props.steps.length,
			isFacebook: this.props.facebook,
			isYahoo: this.props.yahoo,
			isGoogle: this.props.google,
			isThankYouChatBot: this.props.thankYouChatBot,
			currentStep: 1,
			currentStepName: '',
			currentStepButtonID: '',
			nextStep: 2,
			lastStep: false,
			showNextBtn: this.props.steps[0].showButton,
			showDisclaimer: false,
			showTrustLoader: false,
			showSubmit: false,
			showThankYouMessage: false,
			showThankYouPage: false,
			showVerifyInformation: false,
			delay: false,
			isLoading: true,
			loaderProgress: 10,
			showLinearLoader: false,
			showCircularLoader: false,
			showOnLoadLoader: true,
			sliderValue: 0,
			validateAPI: false,
			rules: [],
			verifyInformationResponse: {},
			response: {},
			internalLeadID: '',
			secondInternalLeadID: '',
			submitClientData: {},
			submitLeadData: {},
			postURL: '/api/submit',
			postURLFacebook: '/api/submit-Facebook',
			postURLYahoo: '/api/submit-Yahoo',
			postURLGoogle: '/api/submit-Google',
			postCallNumber: 0,
			tracking: {},
			trackingURLs: {},
			isTesting: (process.env.NODE_ENV === 'development'),
			useTestData: false,
			testingData: {
				tracking: {"utm_source":"taboola","utm_medium":"display","utm_campaign":"8702417","utm_content":"2971640945","utm_term":"msn-msn", "utm_search_term":"SSO12345678sdfs455"},
				response: {
					behind_on_payments: "gt_60days",
					city: "Miami",
					date_of_birth: "08-01-1990",
					day_phone: "954-305-1234",
					email: "priscilla@mailinator.com",
					first_name: "Priscilla",
					// last_name: "Pi",
					state: "FL",
					street: "248 NE 14 St",
					unsecured_debt: "50000",
					zip: "33132",
				}
			},
			getEmailValidURL: '/api/getEmailValid',
			getPhoneValidURL: '/api/getPhoneValid'

		};

		this.readUrlParams = this.readUrlParams.bind(this);
		this.setStateForNextStep = this.setStateForNextStep.bind(this);
		// this.createStepDisclaimer = this.createStepDisclaimer.bind(this);
		this.handleVerifyInformationSubmit = this.handleVerifyInformationSubmit.bind(this);
		this.handleGetValueValidAPI = this.handleGetValueValidAPI.bind(this);
		this.handleStepChange = this.handleStepChange.bind(this);
		this.handleLoaderComplete = this.handleLoaderComplete.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.createDataForAPI = this.createDataForAPI.bind(this);
		this.getEmailValueValidAPI = this.getEmailValueValidAPI.bind(this);
		this.getPhoneValueValidAPI = this.getPhoneValueValidAPI.bind(this);
		this.submit = this.submit.bind(this);
	}

	componentDidMount() {
		this.onLoadLoader();
		this.getTrackingURLs();
		this.setupData();
	}

	onLoadLoader() {
		setTimeout(() => {
			this.setState({
				isLoading: !this.state.isLoading,
				showOnLoadLoader: !this.state.showOnLoadLoader,
			});
		}, 500);
	}

	setupData() {
		if(this.state.isTesting && this.state.useTestData) {
			this.setState({
				tracking: this.state.testingData.tracking,
				response: this.state.testingData.response
			});
		}
		this.readUrlParams();
	}
	
	readUrlParams() {
		// let testReferrerURL = "https://learn.weeklyfinancialsolutions.com/open-program/?utm_source=taboola_sso&utm_medium=display&utm_campaign=9642231&utm_term=omgstudios-tradingblvd&utm_content=2986641628&utm_publisher=1134458&tblci=GiDNYQKNDw5IQttGzHi4gGKZqMiahkuPkhUtsXz3pj7KwiCtuD0omsDG57uz_vzPAQ";
		// let utmParamsObj = grabUTMparams(testReferrerURL);

		let utmParamsObj = grabUTMparams(this.state.trackingURLs.referrer, this.state.isFacebook, this.state.isThankYouChatBot, this.state.isYahoo, this.state.isGoogle);
		let responseParamObj = grabResponseParam('resp');

		if(!isObjEmpty(utmParamsObj)) {
			this.setState({
				tracking: utmParamsObj,
			});
		}

		if(!isObjEmpty(responseParamObj)) {
			Object.keys(responseParamObj).forEach((key, index) => {			
				let value = responseParamObj[key];
				let response = {
					key: key,
					val: value,
				};
				this.setStateForNextStep(response);
			})
		}
	}
	
	getTrackingURLs() {
		this.setState({
			trackingURLs: {
				referrer: document.referrer,
				form: document.URL
			}
		});
	}

	submit() {
		return new Promise((resolve, reject) => {
			let data = {
				submitClientData: this.state.submitClientData,
				submitLeadData: this.state.submitLeadData,
				trackingURLs: this.state.trackingURLs,
				postCallNumber: this.state.postCallNumber,
				internalLeadID: this.state.internalLeadID,
				secondInternalLeadID: this.state.secondInternalLeadID,
				isFacebook: this.state.isFacebook,
				isYahoo: this.state.isYahoo,
				isGoogle: this.state.isGoogle,
			};
			let url = (this.state.isFacebook) ? this.state.postURLFacebook : (this.state.isYahoo) ? this.state.postURLYahoo : (this.state.isGoogle) ? this.state.postURLGoogle : this.state.postURL;
			// console.log('JSON.stringify(data): ', JSON.stringify(data));

			$.ajax({
				type: 'POST',
				url: url,
				data: JSON.stringify(data),
				crossDomain: true,
				success: (response, status, xhr) => {
					console.log('success');
					// console.log('response: ', response);

					let responseParsed = JSON.parse(response);
					// console.log('responseParsed: ', responseParsed);
					setTimeout(() => resolve(responseParsed), 2000);
				},
				error: (xhr, ajaxOptions, thrownError) => {
					// console.log('xhr: ', xhr);

					console.log('error - ', xhr.responseText);
					let responseParsed = JSON.parse(xhr.responseText);
					// console.log('responseParsed: ', responseParsed);
					// setTimeout(() => resolve(responseParsed), 2000);
					setTimeout(() => resolve({valid:false, verifyInformation:true}), 2000);
				},
			});
		});
	}

	getEmailValueValidAPI() {
		return new Promise((resolve, reject) => {
			$.ajax({
				type: 'GET',
				url: this.state.getEmailValidURL,
				data: 'email=' + this.state.response.email,
				crossDomain: true,
				success: (response, status, xhr) => {
					console.log('success');
					let responseParsed = JSON.parse(response);
					console.log('responseParsed: ', responseParsed);
					resolve(responseParsed[0].valid);
				},
				error: (xhr, ajaxOptions, thrownError) => {
					console.log('error');
					resolve(false);
				},
			});
		});
	}

	getPhoneValueValidAPI() {
		return new Promise((resolve, reject) => {
			$.ajax({
				type: 'GET',
				url: this.state.getPhoneValidURL,
				data: 'phone=' + this.state.response.phone,
				crossDomain: true,
				success: (response, status, xhr) => {
					console.log('success');
					let responseParsed = JSON.parse(response);
					if(responseParsed[0].valid) {
						this.saveQuestionnaireResponse('phone', replaceAllHelper(this.state.response.phone.trim(), '-', ''));
					}
					resolve(responseParsed[0].valid);
				},
				error: (xhr, ajaxOptions, thrownError) => {
					console.log('error');
					resolve(false);
				},
			});
		});
	}

	createDataForAPI(resolve, reject) {		
		let companyStartMonth, companyStartYear = '';
		Object.keys(this.state.response).forEach((key, index) => {
			let value = this.state.response[key];
			let newValue = value.trim();			
			
			if (key === 'date_of_birth') {
				// API accepts date format: YYYY-MM-DD
				newValue = formatDateAPI(value);
			}
			if (key === 'Phone') {				
				// API accepts phone number format: nnnnnnnnnn
				newValue = replaceAllHelper(value, '-', '');
			}
			if (key === 'TotalDebt') {
				newValue = parseInt(value);
			}

			if (key === 'company_start-month') {
				companyStartMonth = newValue;
			}

			if (key === 'company_start-year') {
				companyStartYear = newValue;
			}

			if (key === 'state' || key === 'zip' || key === 'street' || key === 'street2' || key === 'city') {
				if(key === 'street') {
					this.setState((prevState) => ({
						submitClientData: {
							...prevState.submitClientData,
							'Address': newValue,
						},
						submitLeadData: {
							...prevState.submitLeadData,
							'Address': newValue,
						}
					}));
				} else if(key === 'city') {
					this.setState((prevState) => ({
						submitClientData: {
							...prevState.submitClientData,
							'City': newValue,
						},
						submitLeadData: {
							...prevState.submitLeadData,
							'City': newValue,
						}
					}));
				} else if(key === 'state') {
					this.setState((prevState) => ({
						submitClientData: {
							...prevState.submitClientData,
							'State': newValue,
						},
						submitLeadData: {
							...prevState.submitLeadData,
							'State': newValue,
						}
					}));
				} else if(key === 'zip') {
					this.setState((prevState) => ({
						submitClientData: {
							...prevState.submitClientData,
							'Zip': newValue,
						},
						submitLeadData: {
							...prevState.submitLeadData,
							'Zip': newValue,
						}
					}));
				}
			} else if (companyStartMonth !== '' && companyStartYear !== '') {
				this.setState((prevState) => ({
					submitClientData: {
						...prevState.submitClientData,
						'company_start': `${companyStartMonth}/01/${companyStartYear}`,
					},
					submitLeadData: {
						...prevState.submitLeadData,
						'company_start': `${companyStartMonth}/01/${companyStartYear}`,
					}
				}));
				companyStartMonth = '';
				companyStartYear = '';
			} else if (key !== 'company_start-month' && key !== 'company_start-year') {
				this.setState((prevState) => ({
					submitClientData: {
						...prevState.submitClientData,
						[key]: newValue,
					},
					submitLeadData: {
						...prevState.submitLeadData,
						[key]: newValue,
					}
				}));
			}

			if(key === 'FirstName' || key === 'LastName' || key === 'HomePhone' || key === 'Email') {
				this.setState((prevState) => ({
					verifyInformationResponse: {
						...prevState.verifyInformationResponse,
						[key]: newValue,
					},
				}));
			}
		});

		let prefix = (this.state.isFacebook) ? 'SSO-FB-' : (this.state.isYahoo) ? 'SSO-YH-' : (this.state.isGoogle) ? 'SSO-GG-' : 'SSO';
		let internalLeadID = (this.state.postCallNumber === 0) ? generateUniqueID(prefix) : this.state.internalLeadID;
		let secondInternalLeadID = (this.state.postCallNumber > 0) ? generateUniqueID(prefix) : this.state.secondInternalLeadID;

		this.setState((prevState) => ({
			postCallNumber: this.state.postCallNumber + 1,
			internalLeadID: internalLeadID,
			secondInternalLeadID: secondInternalLeadID,
			submitClientData: {
				...prevState.submitClientData,
				// LeadId: this.state.tracking.utm_campaign
				// LeadId: '326'
			}
		}));

		resolve();
	}

	async handleSubmit() {
		if(this.state.showVerifyInformation) {
			await this.handleVerifyInformationSubmit();
		}

		this.setState({
			isLoading: !this.state.isLoading,
			showCircularLoader: !this.state.showCircularLoader,
		});
		
		const submitDataPromise = new Promise(this.createDataForAPI);
		submitDataPromise.then(this.submit).then((result) => {		
			// let chatResponse = '';
			// let length = Object.keys(this.state.verifyInformationResponse).length;
			// let pos = 0;

			// $.each(this.state.verifyInformationResponse, (key, value) => {
			// 	pos++;
			// 	let connectingCharacter = (pos === length) ? '' : '&';
			// 	chatResponse = chatResponse + key + '=' + value + connectingCharacter;
			// });

			// let chatResponseEncoded = window.btoa(chatResponse);
			// let chat = '&chat=' + chatResponseEncoded;
			if(result.valid) {
				if(result?.data?.url) {
					window.location.href = result.data.url;
				} else {
					let thankYouURL = (this.state.thankYouURL) ? this.state.thankYouURL : 'thank-you';
	
					// let urlParams = '/' + thankYouURL + '/?SSOleadID=' + this.state.internalLeadID + chat;
		
					let urlParams = '/' + thankYouURL + '/?SSOleadID=' + this.state.internalLeadID;
					window.location.href = window.location.origin + urlParams;
				}
			} else {
				let thankYouURL = (this.state.thankYouURL) ? this.state.thankYouURL : 'thank-you';
	
				// let urlParams = '/' + thankYouURL + '/?SSOleadID=' + this.state.internalLeadID + chat;
	
				let urlParams = '/' + thankYouURL + '/?SSOleadID=' + this.state.internalLeadID;
				window.location.href = window.location.origin + urlParams;
			}
		});
	}

	handleGetValueValidAPI(key, value) {
		return new Promise((resolve, reject) => {
			const getValueValidAPIPromise = new Promise((resolve, reject) => {
				this.saveQuestionnaireResponse(key, value);
				resolve();
			});
			getValueValidAPIPromise.then((key === 'email') ? this.getEmailValueValidAPI : this.getPhoneValueValidAPI).then((res) => {
				resolve(res);
			});
		});
	}

	async validateFormItem() {
		const toggleErrorClasses = (field) => {
			const MUI_ROOT = '.MuiFormControl-root'
			const MUI_ERR_CLASSNAME = 'Mui-error';
			const MUI_ELEMENTS = [
				'.MuiFormLabel-root',
				'.MuiInputBase-root',
				'.MuiFormHelperText-root'
			];

			MUI_ELEMENTS.map(element => field.closest(MUI_ROOT).querySelector(element)?.classList.add(MUI_ERR_CLASSNAME));
		}

		let formStep = document.getElementsByClassName('co-questionnaire__step--active');
		let formItems = formStep && formStep.length ? formStep[0].getElementsByClassName('js-required') : false;
		let isRequired = formItems && formItems.length > 0;

		if (!isRequired) return true;

		let isValid = true;
		let isSelect, isEmail, isPhone, isDate, isCompanyRevenue = false;
		let formField, formFieldName, value, valueMonth, valueYear, valueType, valueCurrency = '';
		
		$.each(formItems, (index, formItem) => {
			formField = formItem.querySelector('input, select');
			formFieldName = formField.name;
			valueType = formField.closest('.MuiFormControl-root').dataset.valueType;
			value = (formField.value = formField.value.trim());
			isSelect = formItem.classList.contains('ca-select-field');
			isEmail = valueType === 'email';
			isPhone = valueType === 'phoneNumber';
			isCompanyRevenue = valueType === 'companyRevenue';

			if (!regExValidation(valueType, value)) {
				toggleErrorClasses(formField);
				isValid = false;
			}

			if(isSelect) {
				isValid = Object.keys(this.state.response).find(key => key === formFieldName && this.state.response[key] !== '');
				if(!isValid) toggleErrorClasses(formField);
			}
		});
		
		if(isValid && (isEmail || isPhone)) {
			// TODO: use line commented below when we add API connection
			isValid = await this.handleGetValueValidAPI(formFieldName, value);
			// isValid = true;
			toggleErrorClasses(formField);
		} 

		// if(isValid && isCompanyRevenue) {
		// 	if(value.length > 0) {
		// 		valueCurrency = parseInt(replaceAllHelper(value.trim(), ',', ''));
		// 		if (valueCurrency < 7500) {
		// 			console.log('8');
		// 			if (this.state.formName) {
		// 				console.log('9');
		// 				if (this.state.formName !== 'tcw15-22') {
		// 					console.log('10');
		// 					setTimeout(() => {window.location.href = "https://app.oneparkfinancial.com/display-results"}, 1000);
		// 				}
		// 			} else {
		// 				console.log('11');
		// 				setTimeout(() => {window.location.href = "https://app.oneparkfinancial.com/display-results"}, 1000);
		// 			}
		// 		}
		// 		if(valueCurrency < 7500) {
		// 			console.log('12');
		// 			if(this.state.formName) {
		// 				console.log('13');
		// 				if(this.state.formName !== 'tcw15-22') {
		// 					console.log('14');
		// 					setTimeout(() => {window.location.href = "https://app.oneparkfinancial.com/display-results"}, 1000);
		// 				}
		// 			} else {
		// 				console.log('15');
		// 				setTimeout(() => {window.location.href = "https://app.oneparkfinancial.com/display-results"}, 1000);
		// 			}

		// 		}
		// 		this.saveQuestionnaireResponse('company_revenue', valueCurrency);
		// 	} else {
		// 		isValid = false;
		// 		toggleErrorClassName(isValid, formItem);
		// 	}
		// }

		return isValid;
	}

	async onButtonClick(e, submitClicked) {
		const saveGoogleAddressResponse = (activeStepGoogleAddress) => {
			let fields = activeStepGoogleAddress.querySelectorAll('input, select');

			if(fields.length) {
				$.each(fields, (index, item) => {			
					let key = item.name;
					let value = (item.value = item.value.trim());
					this.saveQuestionnaireResponse(key, value);
				});
			}
		}
		const sliderResponse = (activeStepSlider) => {
			let sliderInputField = $('.co-questionnaire__step--active .ca-slider-field input');
			let sliderNameValue = {
				key: activeStepSlider.data('slider'),
				val: sliderInputField[0].value,
			};
			return sliderNameValue;
		};

		const disableButton = (button, value = true) => {
			if(this.state.validateAPI) button.disabled = value;
		};

		let target = e.target;

		disableButton(target);

		let valid = await this.validateFormItem();
		
		if (!valid) {
			disableButton(target, false);
			return console.log('error-- value not valid');
		}

		if (isMobile()) scrollToTop();

		let lastStep = target?.classList.contains('js-submit__last-step');

		let activeStepGoogleAddress = $('.co-questionnaire__step--active .ca-places-autocomplete--google');
		if(activeStepGoogleAddress.length) await saveGoogleAddressResponse(activeStepGoogleAddress[0]);

		if (submitClicked && lastStep) {
			this.handleSubmit();
		} else {
			let activeStepSlider = $('.co-questionnaire__step--active .ca-slider-field');
			let response = activeStepSlider.length ? sliderResponse(activeStepSlider) : false;
			this.setStateForNextStep(response, submitClicked);
		}

		disableButton(target, false);
	}

	handleVerifyInformationSubmit() {
		let formStep = document.getElementsByClassName('co-questionnaire__step--active');
		let formItems = formStep && formStep.length ? formStep[0].getElementsByClassName('js-required') : false;

		if(formItems) {
			$.each(formItems, (index, formItem) => {			
				let formField = formItem.querySelector('input');
				let key = formField.name;
				let value = (formField.value = formField.value.trim());
				this.saveQuestionnaireResponse(key, value);
			});
		}
	};

	handleStepChange(e, sliderNewValue = false) {
		const handleSelectChange = (e) => {
			this.saveQuestionnaireResponse(e.name, e.value);
		};
		
		const handleChange = (e) => {
			let saveResponse = e.target.closest('.MuiFormControl-root').dataset.save === 'true';
			let validateAPI = e.target.dataset.validateApi === 'true';

			let formatTextValue = (value, valueType) => {
				switch (valueType) {
					case 'phoneNumber':
						return formatPhoneText(value);
					case 'date':
						return formatDate(value);
					case 'zipCode':
						return formatZipCode(value);
					default:
						return value;
				}
			};
			let key = e.target.name;
			let val = (e.target.value = formatTextValue(e.target.value, e.target.closest('.MuiFormControl-root').dataset.valueType));

			if (saveResponse) {
				this.saveQuestionnaireResponse(key, val);
				if(validateAPI && !this.state.validateAPI) {
					this.setState({
						validateAPI: !this.state.validateAPI,
					});
				}
			}
		};

		const handleOptionChange = (e) => {
			let saveResponse = e.target.dataset.save === 'true';
			let optionNameValue = {
				key: e.target.name,
				val: e.target.value,
			};

			if (this.state.lastStep && saveResponse) {
				this.saveQuestionnaireResponse(optionNameValue.key, optionNameValue.val);
			} else if (!this.state.lastStep) {
				this.setStateForNextStep(saveResponse ? optionNameValue : false);
			}
		};

		const handleSliderChange = (newValue) => {
			this.setState({
				sliderValue: newValue,
			});
		};

		if(e.target) {
			if (e.target.type === 'text' || e.target.type === 'email' || e.target.type === 'select-one') {
				handleChange(e);
			} else if (e.target.type === 'radio') {
				handleOptionChange(e);
			} else if (sliderNewValue !== false) {
				handleSliderChange(sliderNewValue);
			}
		} else if(e.type && e.type === 'select') {
			handleSelectChange(e);
		}
	}

	handleLoaderComplete(isComplete) {
		this.setState({
			isLoading: !isComplete,
			showLinearLoader: !isComplete,
			showCircularLoader: !isComplete,
			showTrustLoader: !isComplete
		});
	}

	setStateForNextStep(response = false, submitClicked = false) {
		const showSubmitBtn = (lastStep) => {
			if (lastStep) return true;

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');
			return formItemNextStep[0].classList.contains('js-show__submit');
		};

		const showNextBtn = () => {
			const isNextButtonNeeded = (step) => {
				return step.getElementsByClassName('js-show--button').length;
			};

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');

			let showNextBtn = formItemNextStep && formItemNextStep.length ? isNextButtonNeeded(formItemNextStep[0]) : false;

			return showNextBtn;
		};

		const showDisclaimer = () => {
			const isDisclaimerNeeded = (step) => {
				return step.getElementsByClassName('js-show--disclaimer').length;
			};

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');

			let showDisclaimer =
				formItemNextStep && formItemNextStep.length ? isDisclaimerNeeded(formItemNextStep[0]) : false;

			return showDisclaimer;
		};

		const showTrustLoader = () => {
			const isTrustLoaderNeeded = (step) => {
				return (step.dataset.loader && step.dataset.loader !== 'false') ? JSON.parse(step.dataset.loader) : false;
			};

			let formItemNextStep = document.getElementsByClassName('co-questionnaire__step--next');

			let showTrustLoader =
				formItemNextStep && formItemNextStep.length ? isTrustLoaderNeeded(formItemNextStep[0]) : false;

			return showTrustLoader;
		};

		const currentStepName = () => {
			if (!showNextBtn()) return '';

			let textField = $('[data-step="' + this.state.nextStep + '"] .ca-text-input-field input');
			let sliderField = $('[data-step="' + this.state.nextStep + '"] .ca-slider-field input');

			if (textField.length) {
				return textField.attr('name');
			} else if (sliderField.length) {
				return sliderField.attr('name');
			}
		};

		const currentStepButtonID = (lastStep) => {
			if (!showNextBtn() && !showSubmitBtn(lastStep)) return '';

			let textField = $('[data-step="' + this.state.nextStep + '"] .ca-text-input-field');
			let sliderField = $('[data-step="' + this.state.nextStep + '"] .ca-slider-field');
			let placesAutocomplete = $('[data-step="' + this.state.nextStep + '"] .ca-places-autocomplete');

			if (placesAutocomplete.length) {
				return placesAutocomplete.data('button-id');
			} else if (textField.length) {
				return textField.data('button-id');
			} else if (sliderField.length) {
				return sliderField.data('button-id');
			}
		};

		let lastStep = this.state.nextStep === this.state.totalSteps;

		this.setState({
			isLoading: !this.state.isLoading,
			showLinearLoader: !this.state.showLinearLoader,
			totalSteps: document.getElementsByClassName('co-questionnaire__step').length,
			currentStep: this.state.nextStep,
			currentStepName: currentStepName(),
			currentStepButtonID: currentStepButtonID(lastStep),
			nextStep: lastStep ? 0 : this.state.nextStep + 1,
			lastStep: lastStep,
			sliderValue: 0,
			showSubmit: showSubmitBtn(lastStep),
			showNextBtn: showNextBtn(),
			showDisclaimer: showDisclaimer(),
			showTrustLoader: showTrustLoader(),
			showThankYouMessage: submitClicked,
			validateAPI: false,
		});

		if (response !== false) {
			this.saveQuestionnaireResponse(response.key, response.val);
		}
	}

	saveQuestionnaireResponse = (key, val) => {
		this.setState((prevState) => ({
			response: {
				...prevState.response,
				[key]: val,
			},
		}));
	};

	renderQuestionnaireSteps = () => {
		const checkRules = (title, rules) => {
			let skipStep = false;
			rules.forEach((rule) => {
				if (title === rule) {
					skipStep = true;
				}
			});
			return skipStep;
		};

		const getRules = (e) => {
			const setStateRules = (rules) => {
				this.setState({
					rules,
				});
			};

			let rules = e.target.getAttribute('data-rules');

			if (rules) {
				rules = rules.split(',').concat(this.state.rules);
				setStateRules(rules);
			}
		};

		const createStepDisclaimer = () => {
			console.log('inside createStepDisclaimer');
		};

		const renderQuestionnaireStepClassNames = (step, showSubmit, state) => {
			let modifierClass =
				step === state.currentStep
					? ' co-questionnaire__step--active'
					: step === state.nextStep
					? ' co-questionnaire__step--next'
					: '';
			let showSubmitClass = showSubmit ? ' js-show__submit' : '';
			let classList = 'co-questionnaire__step' + modifierClass + showSubmitClass;
			return classList;
		};

		let stepNum = 1;
		let steps = [];
		let showSubmit = false;

		this.state.steps.map((step, index) => {
			showSubmit = step.showSubmit ? true : false;
			if (!checkRules(step.title, this.state.rules)) {
				steps.push(
					<QuestionnaireStep
						step={stepNum}
						id={`step-${index + 1}`}
						classNames={renderQuestionnaireStepClassNames(stepNum, showSubmit, this.state)}
						key={index}
						title={step.title}
						subTitle={step.subTitle}
						body={step.body}
						sliderValue={this.state.sliderValue}
						disclaimer={step.disclaimer}
						rules={getRules}
						onChange={this.handleStepChange}
						loader={step.loader}
					/>
				);
				stepNum++;
			}
		});

		return steps;
	};

	renderVerifyInformation = () => {
		return (
			<QuestionnaireStep
				classNames='co-questionnaire__step co-questionnaire__step--active js-show__submit'
				title={this.state.verifyInformation.title}
				subTitle={this.state.verifyInformation.subTitle}
				body={this.state.verifyInformation.body}
				disclaimer={this.state.verifyInformation.disclaimer}
				onChange={this.handleStepChange}
				response={this.state.verifyInformationResponse}
			/>
		)
	}

	render() {
		const questionnaireSteps = !this.state.showVerifyInformation && !this.state.isLoading ? this.renderQuestionnaireSteps() : null;

		const verifyInformation = this.state.showVerifyInformation && !this.state.isLoading ? this.renderVerifyInformation() : null;

		const nextButton =
			this.state.showNextBtn &&
			!this.state.showSubmit &&
			!this.state.isLoading ? (
				<div className='l-grid__row l-grid__center-xs co-questionnaire__button'>
					<Button
						type='primary'
						label={this.state.nextButtonLabel}
						id={this.state.currentStepButtonID}
						onClick={(e) => {
							this.onButtonClick(e);
						}}
						large
						isForm
					/>
				</div>
			) : null;

		const submitButton = this.state.showSubmit ? (
			<Button
				type='primary'
				label={this.state.submitButtonLabel}
				id={this.state.currentStepButtonID}
				classNames='co-questionnaire__button'
				linkClassNames={this.state.lastStep ? 'js-submit__last-step' : null}
				onClick={(e) => {
					this.onButtonClick(e, true);
				}}
				large
				isForm
			/>
		) : null;

		const disclaimerAboveButton =
			this.state.showSubmit &&
			!this.state.isLoading &&
			this.state.disclaimer.aboveSubmitButton ? (
				<div className='co-questionnaire__disclaimer co-questionnaire__disclaimer--above-btn'>
					<p>{this.state.disclaimer.aboveSubmitButton}</p>
				</div>
			) : null;

		const disclaimer =
			this.state.showSubmit && !this.state.isLoading ? (
				<div className='co-questionnaire__disclaimer'>
					<p dangerouslySetInnerHTML={{ __html: this.state.disclaimer.belowSubmitButton }} />
				</div>
			) : null;

		const phoneDisclaimerBtnText = this.state.lastStep ? 'Submit' : 'Next';
		const disclaimerTelephone =
			this.state.currentStepName === 'Phone' && !this.state.isLoading ? (
				<div className='co-questionnaire__disclaimer'>
					<p>{this.state.disclaimer.phone}</p>
				</div>
			) : null;

		const thankYouMessage = this.state.showThankYouMessage ? (
			<div className='co-questionnaire__message'>
				<Heading
					title={this.state.onSubmitMessage.title}
					titleType='h2'
					subTitle={this.state.onSubmitMessage.subTitle}
					subTitleType='h3'
				/>
			</div>
		) : null;

		const form =
			!this.state.showThankYouMessage && !this.state.isLoading ? (
				<form id='questionnaire-form' className='l-grid__row l-grid__center-xs'>
					{questionnaireSteps}
					{verifyInformation}
					{disclaimerAboveButton}
					{nextButton}
					{submitButton}
					{disclaimer}
				</form>
			) : null;

		const linearLoader =
			this.state.isLoading && this.state.showLinearLoader && !this.state.showTrustLoader ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader 
						title={this.state.messages.savingAnswer}
						onComplete={this.handleLoaderComplete} 
						isMui
					/>
				</div>
			) : null;

		const circularLoader =
			this.state.isLoading && this.state.showCircularLoader ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader 
						title={this.state.messages.processingSubmit}
						isCircular 
					/>
				</div>
			) : null;
		
		const onLoadLoader =
			this.state.isLoading && this.state.showOnLoadLoader ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader 
						title='Loading...' 
						isCircular 
					/>
				</div>
			) : null;

		const trustLoader =
			this.state.isLoading && this.state.showTrustLoader ? (
				<div className='co-questionnaire__loader l-grid__row l-grid__center-xs'>
					<Loader 
						trustContent={this.state.showTrustLoader} 
						onComplete={this.handleLoaderComplete} 
						isMui
						isTrust
					/>
				</div>
			) : null;

		return (
			<div className='co-questionnaire l-spacing__p-b--24'>
				<div className='co-questionnaire__wrapper'>
					{thankYouMessage}
					{form}
					{linearLoader}
					{circularLoader}
					{onLoadLoader}
					{trustLoader}
					{/* {disclaimerTelephone} */}
				</div>
			</div>
		);
	}
}

export default Questionnaire;
