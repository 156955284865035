import React from 'react'
import SEO from "../../components/atoms/seo"

import Layout from '../../components/organisms/layout'
import Heading from '../../components/molecules/heading'
import Questionnaire from '../../components/organisms/questionnaire'
import KeyFeatures from '../../components/molecules/key-features'

import steps from "../../data/pages/es/questionnaire/steps.json"
import content from "../../data/pages/es/questionnaire/content.json"

const QuestionnairePageES = () => (
  <Layout content={content.layout}>
    <SEO title={content.seo.title} />
    <Heading 
        title={content.heading.title}
        subTitle={content.heading.subTitle}
        weight='light'
        />
    <Questionnaire 
      steps={steps}
      content={content.questionnaire}
      disclaimer={content.questionnaire.disclaimers}
      spanish
    />
    <KeyFeatures
      features={content.keyFeatures}
    />
  </Layout>
)

export default QuestionnairePageES


// const QuestionnairePage = () => (
//   <Layout showBG showFormSecureBanner>
//     <SEO title={content.seo.title} />
//     <Heading 
//         title={content.heading.title}
//         subTitle={content.heading.subTitle}
//         weight='light'
//     />
//     <Questionnaire 
//       steps={steps}
//       verifyInformation={verifyInformation}
//       disclaimer={content.disclaimer}
//       onSubmitMessage={content.onSubmit.message}
//       thankYouURL={content.onSubmit.thankYouURL}
//       thankYouChatBot
//     />
//     <KeyFeatures
//       features={[
//         {
//             title: 'Over $3 billion debt&nbsp;resolved',
//             image: 'handshake.png',
//             imageAlt: ''
//         },
//         {
//             title: 'Over 170,000 Americans&nbsp;helped',
//             image: 'human.png',
//             imageAlt: ''
//         },
//         {
//             title: 'Certified Debt&nbsp;Consultants',
//             image: 'customer-support.png',
//             imageAlt: ''
//         }
//     ]}
//     />
//   </Layout>
// )

// export default QuestionnairePage
