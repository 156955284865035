import React from "react"
import Title from "../atoms/title"

const KeyFeatures = ({ 
    classNames = false,
    type = false,
    features = []
}) => {

    const createClassList = () => {
        let classList = 'l-section cm-key-features';
        if(type) classList = classList + ' cm-key-features--' + type;
        if(classNames) classList = classList + ' ' + classNames;
        return classList;
    }

    const createColumns = () => {
        let columns = [];
        features.map((feature, index) => {
            columns.push((
                <div className="l-grid__col-xs-12 l-grid__col-md-4" key={index}>
                    <div className={`cm-key-features__col cm-key-features__col--${index+1}`}>
                        <div className="cm-key-features__img">

                            <img src={require(`../../images/opf/${feature.image}`)} alt={feature.imageAlt} />
                        </div>
                        <div className="cm-key-features__copy">
                            <Title
                                type='h5'
                                title={feature.title}
                                align='center'
                                color='dark'
                            />
                        </div>
                    </div>
                </div>
            ));
        })
        return columns;
    }

    return (
        <div className={createClassList()}>
            <div className="l-grid__row cm-key-features__wrapper">
                {createColumns()}
            </div>
        </div>
    );
}

export default KeyFeatures;